<template>
    <div>
        <v-row no-gutters class="pt-3 pl-0 pb-3" dense>
            <div class="d-flex flex-row align-center">
                <v-select
                    :items="years"
                    :style="'max-width: 200px'"
                    clearable
                    dense
                    hide-details="auto"
                    solo
                    v-model="filters.year"
                ></v-select>
                <v-select
                    :items="months"
                    :placeholder="$t('message.all')"
                    :style="'max-width: 200px'"
                    class="ml-3"
                    clearable
                    dense
                    hide-details="auto"
                    item-text="Month.code"
                    item-value="Month.id"
                    v-model="filters.monthFrom"
                    solo
                ></v-select>
                <span class="pl-2">{{ $t('message.to') }}</span>
                <v-select
                    :items="months"
                    :placeholder="$t('message.all')"
                    :style="'max-width: 200px'"
                    class="ml-3"
                    clearable
                    dense
                    hide-details="auto"
                    item-text="Month.code"
                    item-value="Month.id"
                    solo
                    v-model="filters.monthTo"
                ></v-select>
                <v-btn
                    :loading="loading"
                    class="ml-2"
                    @click="loadDebitNoteSchedule()"
                >{{ $t('message.fetch') }}
                </v-btn>
            </div>
        </v-row>
        <div>
            <v-sheet class="dense-inputs">
                <v-row no-gutters dense>
                    <v-col cols="4" lg="4" md="6" xs="12" class="d-flex flex-row float-right">
                        <v-text-field
                            :label="$t('message.filterResults')"
                            :value="searchTerm"
                            autocomplete="off"
                            class="mt-2 mb-0 force-text-left"
                            prepend-inner-icon="mdi-filter-outline"
                            @change="searchTerm = $event"
                        ></v-text-field>
                        <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                        <v-btn class="ml-3" @click="searchTerm = null"><v-icon>refresh</v-icon></v-btn>
                        <ExportTableDebitNoteSchedule
                            :export-params="exportDebitNoteScheduleParams"
                            class="ml-2"
                        ></ExportTableDebitNoteSchedule>
                    </v-col>
                </v-row>
            </v-sheet>
            <v-overlay
                :value="loading"
                absolute
                opacity="0.15"
            >
                <v-row>
                    <v-col class="text-center">
                        <v-progress-circular
                            color="primary"
                            indeterminate
                            size="40"
                            width="6"
                        ></v-progress-circular>
                    </v-col>
                </v-row>
            </v-overlay>
            <v-data-table
                :footer-props="{
                    itemsPerPageOptions: [20,50,75,-1],
                    showCurrentPage: true,
                    showFirstLastPage: true
                }"
                :fixed-header="true"
                :headers="headers"
                :height="tableHeight"
                :hide-default-footer="true"
                :items="items"
                :items-per-page="-1"
                :search="searchTerm"
                calculate-widths
                class="appic-table-light specification-table"
                dense
                id="contractScheduleTable"
                item-key="Contract.id"
                sort-by="DebitNote.date"
                sort-desc
            >
                <template v-slot:item.Contract.id="{ item }">
                    <v-menu>
                        <template v-slot:activator="{ on: menu }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn icon v-on="{ ...tooltip, ...menu}">
                                        <v-icon>more_vert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.moreActions') }}</span>
                            </v-tooltip>
                        </template>
                        <v-list dense>
                            <v-list-item class="font-sm" @click="createDebitNote(item.Customer.id)" v-if="$can('create','DebitNote') && item.DebitNote.no == null">
                                <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>
                                {{ $t('message.createDebitNote') }}
                            </v-list-item>
                            <v-list-item class="font-sm" @click="updateDebitNote(item.DebitNote.id)" v-else>
                                <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>
                                {{ $t('message.updateDebitNote') }}
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
                <template v-slot:item.Contract.title="{ item }">
                    <span class="font-weight-bold">{{ item.Contract.title }}</span>
                </template>
                <template v-slot:item.DebitNote.no="{ item }">
                    <span class="text-no-wrap font-weight-bold" v-if="item.DebitNote.no != null">{{ item.DebitNote.no }}</span>
                    <span class="text-no-wrap red--text lighten-1" v-else>{{ $t('message.pending') }}</span>
                </template>
                <template v-slot:item.DebitNote.date="{ item }">
                    {{ item.DebitNote.date != '0000-00-00' ? formatDate(item.DebitNote.date) : '' }}
                </template>
                <template v-slot:item.Contract.etddate="{ item }">
                    {{ item.Contract.etddate != '0000-00-00' ? formatDate(item.Contract.etddate) : '' }}
                </template>
                <template v-slot:item.Contract.etadate="{ item }">
                    {{ item.Contract.etadate != '0000-00-00' ? formatDate(item.Contract.etadate) : '' }}
                </template>
                <template v-slot:item.Contract.amount="{ item }">
                    <span class="text-right">{{ formatThisNumber(item.Contract.amount,'0,0.00') }}</span>
                </template>
                <template v-slot:item.DebitNote.amount="{ item }">
                    <span class="text-right">{{ formatThisNumber(item.DebitNote.amount,'0,0.00') }}</span>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
import {formatDate, numberFormat} from "Helpers/helpers";
import {mapFields} from "vuex-map-fields";
import {mapGetters} from "vuex";
import {api} from "Api";

const ExportTableDebitNoteSchedule = () => import("Components/Appic/ExportTableDebitNoteSchedule");

export default {
    name: "DebitNoteSchedule",
    components: {ExportTableDebitNoteSchedule},
    data() {
        return {
            filters: {
                year: new Date().getFullYear(),
                monthFrom: null,
                monthTo: null,
                paymentStatus: null
            },
            items: [],
            loading: false,
            searchTerm: null,
            tableHeight: '500',
            tableOptions: {}
        }
    },
    computed: {
        ...mapFields('report',{
            exportDebitNoteScheduleParams: 'exportDebitNoteScheduleParams',
        }),
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        ...mapGetters([
            'months'
        ]),
        formatDate: () => formatDate,
        headers() {
            let headers = [
                {
                    id: 1,
                    text: this.$t('message.action'),
                    value: 'Contract.id',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 2,
                    text: this.$t('message.contract'),
                    value: 'Contract.title',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 3,
                    text: this.$t('message.company'),
                    value: 'Office.title',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 4,
                    text: this.$t('message.buyer'),
                    value: 'Customer.name',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 5,
                    text: this.$t('message.supplier'),
                    value: 'Supplier.name',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 6,
                    text: this.$t('message.sales'),
                    value: 'Salescontact.name',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 7,
                    text: this.$t('message.debitNoteNo'),
                    value: 'DebitNote.no',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 8,
                    text: this.$t('message.debitNoteDate'),
                    value: 'DebitNote.date',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 9,
                    text: this.$t('message.typeInsurance'),
                    value: 'Contract.type_insurance',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 10,
                    text: this.$t('message.buyerPaymentTerm'),
                    value: 'CustomerPayment.status',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 11,
                    text: this.$t('message.etd'),
                    value: 'Contract.etddate',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 12,
                    text: this.$t('message.eta'),
                    value: 'Contract.etadate',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 13,
                    text: this.$t('message.cur'),
                    value: 'Currency.code',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 14,
                    text: this.$t('message.debitNoteAmount'),
                    value: 'DebitNote.amount',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    cellClass: 'text-end'
                },
                {
                    id: 15,
                    text: this.$t('message.contractValue'),
                    value: 'Contract.amount',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    cellClass: 'text-end'
                }
            ]
            return headers
        },
        years(){
            let years = []
            let start = 2020
            let end = new Date().getFullYear();
            let i
            for(i = end; i >= start; i--){
                years.push({value: i, text: i.toString()})
            }
            return years
        }
    },
    methods: {
        createDebitNote(val) {
            let tab = window.open('/v1/debitnotes/add','_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
        filterResults() {},
        formatThisNumber (value,format) {
            return numberFormat(value,format)
        },
        handleResize() {
            this.tableHeight = window.innerHeight - (275);
        },
        loadDebitNoteSchedule() {
            let conditions = []
            if(this.filters.year != null){
                let condition = {
                    field: 'filterYear',
                    value: this.filters.year
                }
                conditions.push(condition)
            }
            if(this.filters.monthFrom != null){
                let condition = {
                    field: 'filterMonthFrom',
                    value: this.filters.monthFrom
                }
                conditions.push(condition)
            }
            if(this.filters.monthTo != null){
                let condition = {
                    field: 'filterMonthTo',
                    value: this.filters.monthTo
                }
                conditions.push(condition)
            }
            return new Promise((resolve, reject) => {
                api
                    .get('/reports/finance/debit-note-schedule',{
                        params: {
                            conditions: conditions
                        }
                    })
                    .then(response => {
                        this.items = response.data.data
                        this.exportDebitNoteScheduleParams = { conditions: conditions }
                        resolve('done')
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        sortByPendingAndDate() {

        },
        updateExportParams() {

        },
        updateDebitNote(val) {
            let tab = window.open('/v1/debitnotes/update/' + val,'_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
    },
    created () {
        window.addEventListener('resize', this.handleResize)
        this.loadDebitNoteSchedule()
    },
    mounted() {
        this.handleResize()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style>
.col-2-5 {
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
}
.font-xs {
    font-size: 0.60rem;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 2px;
    font-size: 0.7rem !important;
    line-height: 0.7rem;
}
.v-data-table th span {
    font-family: 'Arial Narrow', Arial, SansSerif;
    font-size: 0.7rem !important;
    font-weight: normal;
    line-height: 0.7rem;
}
.v-data-table-header th {
    white-space: nowrap;
}
</style>